var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CommonAlertDialog',{attrs:{"show":_vm.showAlert,"item":_vm.alertItem},on:{"onCloseAlert":_vm.onCloseAlert}}),_c('ImportPdfDialog',{attrs:{"show":_vm.showDialog,"item":_vm.dialogItem},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.submit}}),_c('WaitingDialog',{attrs:{"show":_vm.showWaiting}}),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mb-2 ml-2",attrs:{"color":"primary","dark":""},on:{"click":_vm.importApplyPdf}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")]),_vm._v(" "+_vm._s(_vm.$t("container.import-pdf"))+" ")],1)],1)],1),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('container.custom-deliver-fee-after')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":"","show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100]},on:{"update:options":updateOptions}})]}},{key:"item.total_cubic_feet",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.fixedFloat(item.total_cubic_feet,2,0)))]}},{key:"item.compare_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("Translate")(_vm._f("getText")(_vm.parseCompareStatus(item),_vm.compare_status_items)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.viewItem(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container.apply-custom-price-setting")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","color":_vm.uncomparePdfDetail(item)?'green':'',"disabled":!_vm.uncomparePdfDetail(item)},on:{"click":function($event){return _vm.viewPdfItem(item)}}},on),[_vm._v("mdi-file-pdf-box")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container.pdf-left-data")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":"","disabled":_vm.parseCompareStatus(item)==1},on:{"click":function($event){return _vm.revertCompare(item)}}},on),[_vm._v("mdi-alert")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container.revert-compare")))])])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }