<template>
    <v-container fluid>
      <CommonAlertDialog
        :show="showAlert"
        :item="alertItem"
        v-on:onCloseAlert="onCloseAlert"
      >
      </CommonAlertDialog>
      <ImportPdfDialog
        :show="showDialog"
        :item="dialogItem"
        v-on:onClose="onCloseEvent"
        v-on:onSave="submit"
      ></ImportPdfDialog>
      <WaitingDialog :show="showWaiting"></WaitingDialog>
      <v-row>
        <v-col class="text-right">
          <v-btn
            color="primary"
            dark
            class="mb-2 ml-2"
            @click="importApplyPdf"
          >
            <v-icon>mdi-file-pdf-box</v-icon>
            {{ $t("container.import-pdf") }}
          </v-btn>
        </v-col>
      </v-row>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="$t('container.custom-deliver-fee-after')"
        class="px-5 py-3"
      >
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="footerProps"
          multi-sort
          show-select
        >
          <template v-slot:top="{pagination, options, updateOptions}">
            <v-data-footer 
              :pagination="pagination" 
              :options="options"
              @update:options="updateOptions"
              :show-first-last-page="true"
              :show-current-page="true"
              first-icon="mdi-chevron-double-left"
              last-icon="mdi-chevron-double-right"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              :items-per-page-options="[20, 50, 100]"
            />
          </template>
          <template v-slot:item.total_cubic_feet="{item}">{{ fixedFloat(item.total_cubic_feet,2,0) }}</template>
          <template v-slot:item.compare_status="{item}">
            {{ parseCompareStatus(item) | getText(compare_status_items) | Translate }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click="viewItem(item)"
                >mdi-pencil</v-icon>
              </template>
              <span>{{ $t("container.apply-custom-price-setting") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click="viewPdfItem(item)"
                  :color="uncomparePdfDetail(item)?'green':''"
                  :disabled="!uncomparePdfDetail(item)"
                >mdi-file-pdf-box</v-icon>
              </template>
              <span>{{ $t("container.pdf-left-data") }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click="revertCompare(item)"
                  :disabled="parseCompareStatus(item)==1"
                >mdi-alert</v-icon>
              </template>
              <span>{{ $t("container.revert-compare") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </base-material-card>
    </v-container>
  </template>
  <script>
  import BaseTable from "./BaseTable.vue";
  import ImportPdfDialog from "../dialogs/ImportPdfDialog";
  import WaitingDialog from "../dialogs/WaitingDialog";
  import CommonAlertDialog from "../dialogs/CommonAlertDialog";
  import { action_items,container_status ,compare_status_items} from "@/definition.js";
  export default {
    data: (vm) => ({
      url: "/container/container/",
      headers: [
        {
          sortable: true,
          text: vm.$i18n.t("ID"),
          value: "id",
        },
        {
          sortable: true,
          text: vm.$i18n.t("container.number"),
          value: "container_number",
        },
        {
          sortable: true,
          text: vm.$i18n.t("container.order_number"),
          value: "order_number",
        },
        {
          sortable: false,
          text: vm.$i18n.t("container.compare-status"),
          value: "compare_status",
        },
        {
          sortable: true,
          text: vm.$i18n.t("container.total_box_amount"),
          value: "total_box_amount",
        },
        {
          sortable: true,
          text: vm.$i18n.t("container.total_cubic_feet"),
          value: "total_cubic_feet",
        },
        {
          sortable: true,
          text: vm.$i18n.t("container.stuffing-date"),
          value: "stuffing_date",
        },
        {
          sortable: true,
          text: vm.$i18n.t("container.cutoff-date"),
          value: "cutoff_date",
        },
        {
          sortable: false,
          text: vm.$i18n.t("actions"),
          value: "actions",
        },
      ],
      action_items: action_items,
      container_status: container_status,
      compare_status_items: compare_status_items,
      showWaiting: false,
      errorTitle: '',
      errorContent: '',
      selected: [],
    }),
    mixins: [BaseTable],
    components: {
      ImportPdfDialog,
      WaitingDialog,
      CommonAlertDialog,
    },
    methods: {
      viewItem(item) {
        console.log("item", item);
        this.$router.push({
          name: "CustomDeliverFeeAfterDetail",
          query: {
            container: item.id,
            name: item.container_number,
            mode: item.status >= 3 ? 2 : 1,
          },
        });
      },
      exportApplyList(item) {
        let objectDate = new Date();
        let file_name =
          objectDate.getFullYear() +
          "年" +
          objectDate.getMonth() +
          "月" +
          objectDate.getDate() +
          "日_" +
          item.container_number +
          "_報關用.xlsx";
        const url = "/container/container/" + item.id + "/getApplyList/";
        this.getFileApi(url, file_name);
      },
      importApplyPdf() {
        if (this.selected.length>0) {
          this.action = this.action_items.create;
          this.showDialog = true;
        } else {
          alert(this.$i18n.t('alert.selected-container-empty'))
        }
      },
      submit(data) {
        this.showDialog = false;
        this.showWaiting = true;
        this.uploadApplyPdf(data);
      },
      uploadApplyPdf(data) {
        const currentObj = this;
        const url =
          process.env.VUE_APP_SERVER_URL + "/container/apply_custom_file/";
        const formData = new FormData();
        formData.append("file", data["pdf_binary"]);
        var ids = [];
        for (const [key, container] of Object.entries(this.selected)) {
          ids.push(container.id);
        }
        formData.append('containers',ids)
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "JWT " + this.token,
          },
        };
        this.axios
          .post(url, formData, config)
          .then(function (response) {
            console.log(response);
            currentObj.showWaiting = false;
          })
          .catch(function (error) {
            currentObj.showWaiting = false;
            console.log(error.response);
            if (currentObj.allowAlert) {
              currentObj.alertItem = error.response.data;
              currentObj.showAlert = true;
            }
            currentObj.loading = false;
          })
          .finally(function () {
            currentObj.getApi();
          });
      },
      forceStatusClose(item) {
        this.postApi([], "/container/container/"+item.id+"/forceClose/");
      },
      revertCompare(item){
        this.postApi([], "/container/container/"+item.id+"/revertCompare/");
      },
      viewPdfItem(item){
        this.$router.push({
          name: "CustomDeliverFeeAfterPdfDetail",
          query: {
            declaration: item.declaration.id,
            container_id: item.id
          },
        });
      },
      parseCompareStatus(item){
        if (item.compare_status==1) {
          return 1
        } else if (item.compare_status==3 && item.declaration.status==3) {
          return 3
        }
        return 2
      },
      uncomparePdfDetail(item){
        return (item.compare_status==3 && item.declaration.status==2)
      }
    },
    mounted() {
      this.getParams['status__lt'] = this.container_status.disable
    },
  };
  </script>
  
