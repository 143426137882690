<template>
  <div
    class="waiting"
    v-if="show"
  >
    <div style="align-self: center;">
      <v-progress-circular
        indeterminate
        :size="50"
        :width="2"
        color="#4caf50"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: false,
  },
};
</script>
<style lang="sass">
.waiting
    align-item: center
    display: flex
    height: 100%
    justify-content: center
    left: 0
    position: fixed
    top: 0
    transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms
    width: 100%
    z-index: 500
    outline: none
    background-color: black
    opacity: 50%
</style>